import matchtitle from '../../components/matchtitle.vue';
import {

	Message,
	Loading
} from 'element-ui'
import {
	handlePageQueryFormSignupTable,
	handleDeleteFormSignupTable,
	handleQueryMatchTeam
} from '@index/api/mymatchs/formsignuptable';
let loading = null;
export default {
	name: 'formsignuptable',
	components: {
		matchtitle
	},
	data() {
		return {
			havAuth: false,
			headers: {
				'Authorization': sessionStorage.getItem('token')
			},
			path: process.env.VUE_APP_BASE_API + "formsignuptable/upload",
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			teams: [],
			matchId: sessionStorage.getItem("currMatchId"),
			uploadFileParams: {
				matchId: sessionStorage.getItem("currMatchId")
			},
			sumFee: 0,
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
		this.queryMatchTeam();
	},
	mounted() {

	},
	destroyed() {

	},
	methods: {
		initAuth(havAuth) {
			this.havAuth = havAuth;
			this.havAuth = true;
		},
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryFormSignupTable({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con1: this.queryForm.con1,
				team: this.queryForm.team,
				matchId: this.matchId,
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.sumFee = res.extendData.sumFee;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},

		uploadBefore() {
			loading = Loading.service({
				lock: true,
				text: '处理中...',
				background: 'rgba(0, 0, 0, 0.7)'
			})
		},
		uploadSuccess(res) { //上传成功
			loading.close();
			this.$refs.upload.clearFiles();
			if (res.status != 200) {
				Message({
					message: '上传失败!',
					type: 'error'
				});
				return;
			}
			Message({
				message: '上传成功!',
				type: 'success',
				duration: 5 * 1000,
			});

			this.$router.go(0);
			// this.currPage = 1;
			// this.queryForm.con = "";
			// this.pageQuery();

		},
		deleteFormSignupTable() {
			this.$confirm('请确认是否删除正式报名表?', '删除正式报名表', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				confirmButtonClass: 'confirmButtonClass'
			}).then(() => {
				handleDeleteFormSignupTable(this.matchId).then(res => {
					if (res.status == 200) {
						Message({
							type: "success",
							message: res.msg,
							duration: 5 * 1000
						});
						this.pageQuery();
					} else {
						Message({
							type: "error",
							message: res.msg,
							duration: 5 * 1000
						});
					}
				});
			});
		},
		queryMatchTeam() { //查询比赛代表队
			handleQueryMatchTeam(this.matchId).then(res => {
				if (res.status == 200) {
					this.teams = res.data;
				}
			});
		},


	}
}
