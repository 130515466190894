import request from '@index/utils/request'


export function handlePageQueryFormSignupTable(data) { //分页查询正式报名表
  return request({
    url: 'formsignuptable/pageQueryFormSignupTable',
    method: 'post',
    data
  })
}


export function handleDeleteFormSignupTable(matchId) { //删除正式报名表
  return request({
    url: 'formsignuptable/deleteFormSignupTable',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}


export function handleQueryMatchTeam(matchId) { //查询比赛代表队
  return request({
    url: 'formsignuptable/queryMatchTeam',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}

export function handleQueryMatchSumFee(matchId) { //查询比赛服务合计费用
  return request({
    url: 'formsignuptable/queryMatchSumFee',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}
